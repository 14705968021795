// 我的浏览记录
import React, {Component} from 'react';
import { Tabs,ListView,Picker, Toast,Button} from 'antd-mobile'
import '../../res/style/myEnroll.less'
import Config from "../../config"

import EnrollList from './components/EnrollList'
import {inject, observer} from "mobx-react/index"
import WxApi from "../../common/wxapi"

@inject("myEnrollStore", "enrollStore")
@observer

class App extends Component{
    
    state={
        enrollList:[],
        isWeixin:true,//判断是否在微信环境
		isMobile:true,//判断是否是移动环境
        isUnionPay:false,
        currentPage:1,
        currentType:0,
        loadMore:false,
        refreshing:false,
        userInfo:{},
        deactivate:'正在加载中...',
        height: document.documentElement.clientHeight,
        showDialog:true,
        cancel_status:-1,
        cancelText:'全部',     
        district:[
            {label:'全部',value:-1},
            {label:'已核销',value:1},
            {label:'未核销',value:0},
        ],
        pick_status:0,
        pickList: [
            {label:"全部",value:0},
            {label:"进行中",value:1},
            {label:"未开始",value:2},
            {label:"已截止",value:3},
            // {label:"已过期",value:4},
            {label:"我管理的",value:5},
            {label:"我发起的",value:6}
        ],
        pickText:'全部',
        time_status:1,
        timeList:[
            {label:"按截止时间倒序",value:1},
            {label:"按创建时间倒序",value:2},
        ],
        showHeader:false,
        overDay:0,//过期天数
        expire_bak_total:0,//回收站总数
    };

    componentDidMount(){
        document.title = '我的记录'
        if(!Config.TOKEN)return
         // 获取用户信息
         this.props.enrollStore.getUserInfo().then(() => {
            let {userInfo} = this.props.enrollStore                                                     
            if (userInfo.expire && userInfo.auth_status == 4) {              
                let nowDate = new Date().getTime()
                let endTimer = new Date(userInfo.expire).getTime()
                let diffTime = nowDate - endTimer
                this.state.overDay = Math.ceil(diffTime/1000/60/60/24)             
            }          
            sessionStorage.setItem('unionid',userInfo.unionid)
            this.setState({userInfo,overDay:this.state.overDay})
        });

        let tabIndex = sessionStorage.getItem('tabIndex')||0
        if(WxApi.getQueryString('tab')){
            tabIndex = WxApi.getQueryString('tab')
        }
        this.changeTab(Number(tabIndex))
        window.addEventListener('scroll',()=>{ //监听页面滚动，设置固定头部
            if(this.state.currentType == 1 || this.state.currentType == 2){
                let top = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
                if(top > 100 && !this.state.showHeader){       
                    this.setState({showHeader:true})
                }else if(top == 0 && this.state.showHeader){
                    this.setState({showHeader:false})
                }
            }           
        },true)
    };
    
    render(){
        const tabs2 = [
            { title: '最近浏览', sub: '1' },
            { title: '我管理的', sub: '2' },
            { title: '我参与的', sub: '3' },
            { title: '我关注的', sub: '4'}
          ];
 
        const ds = new ListView.DataSource({
            rowHasChanged: (r1, r2) => r1 !== r2
          });

        const dataSource = ds.cloneWithRows(this.state.enrollList.slice())

        const row = (rowData, sectionID, rowID)=>{
            return
        }
        return( 
            <div className="main-enroll-box">
               <div className="tab-box" style={{'WebkitOverflowScrolling': 'touch'}}>
              
                {
                    (this.state.currentType == 0 && this.state.expire_bak_total > 0) || this.state.currentType == 1 ?
                    <div className='search-items recycle-box' style={{display:'flex',marginRight:0, pointerEvents: 'none'}}>
                       <div className='cen-recycle' style={{pointerEvents: 'auto'}} onClick={(e)=>{
                            e.stopPropagation();
                            this.props.history.push('/recycleBin');
                        }}>
                            <img className='icon-delete' src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20241220/734a57eca0cd0dd7f0d1aeabd42c9384.png"></img>                            
                            {
                                this.state.expire_bak_total > 0 ? 
                                <span className='recycle-total'>{this.state.expire_bak_total > 99 ? '99' : this.state.expire_bak_total}</span> : ''
                            }  
                            <span>回收站</span>                          
                        </div>                                               
                    </div> : null
                }                
                <Tabs tabs={tabs2} page={this.state.currentType} onChange={(tab, index) => { this.changeTab(index) }}  swipeable={false}></Tabs>
                {
                    this.state.currentType == 2 ? 
                    <div className={this.state.showHeader ?'search-box fixed-search-header':'search-box'}>
                        <Picker data={this.state.district} cols={1} onChange={(val)=>{
                            let i = this.state.district.findIndex(e=>val[0] == e.value)                            
                            this.setState({
                                cancel_status:val[0],
                                cancelText:this.state.district[i].label,
                                enrollList:[]
                            },()=>{
                                this.getManageData()
                            })
                        }}>                                             
                            <span className='select-btn'>{this.state.cancelText}</span>
                        </Picker>
                        <div className='search-btn' onClick={()=>{
                            this.props.history.push('/searchEnroll?type=myEnroll')
                        }}>
                            <i className='iconfont icon-sousuo1'></i>
                            <span>搜索</span>
                        </div>                        
                    </div> 
                    : ''
                }               
                {
                    this.state.currentType == 1 ? 
                    <div className={this.state.showHeader ?'search-box fixed-search-header':'search-box'}>
                        <Picker data={this.state.pickList} value={[this.state.pick_status]} cols={1} onChange={(val)=>{
                             let i = this.state.pickList.findIndex(e=>val[0] == e.value)    
                            this.setState({
                                pick_status:val[0],                            
                                pickText:this.state.pickList[i].label,                         
                                enrollList:[]
                            },()=>{                                
                                this.getManageData()
                            })
                        }}>                                     
                            <span className='select-btn pick-btn'>{this.state.pickText}</span>
                        </Picker>
                        <Picker data={this.state.timeList} value={[this.state.time_status]} cols={1} onChange={(val)=>{
                            this.setState({
                                time_status:val[0],                             
                                enrollList:[]
                            },()=>{                            
                                this.getManageData()
                            })
                        }}>                                 
                            <span className='select-btn pick-btn'>{this.state.timeList[this.state.time_status-1].label}</span>
                        </Picker>
                        <div className='search-items' onClick={()=>{
                            this.props.history.push('/searchEnroll?type=myEnroll&time_type=11')
                        }}>
                            <i className='iconfont icon-sousuo1'></i>
                            <span>搜索</span>
                        </div>  
                   </div> : ''
                }            
                {
                    this.renderAuthTips()
                }
                <ListView 
                    style={{'minHeight':'101%'}}
                    ref={el => this.lv = el}  
                    dataSource={dataSource}
                    renderFooter={() => (<div style={{textAlign: 'center'}}>
                        {this.state.deactivate}
                    </div>)}
                    renderRow={row}
                    renderBodyComponent={() => <EnrollList enrollList={this.state.enrollList} userInfo={this.state.userInfo} currentType={this.state.currentType} changeData={this.ChildrenChange.bind(this)}/>}
                    useBodyScroll={true}
                    pageSize={20}
                    scrollRenderAheadDistance={100}
                    onEndReachedThreshold={20}
                    onEndReached={()=>{this.refreshData()}}
                />
               </div>
            </div>
        )
    };
    // 认证提示框
    renderAuthTips(){
        if((this.state.currentType == 0 || this.state.currentType == 1) && this.state.userInfo.auth_status == 4){//认证过期
            return <div className='auth-status-box' onClick={()=>{
                    sessionStorage.setItem('sourceType','history_list_auth')
                    this.props.history.push('/auth')
                }}>
                <div className="left-box">
                    <span className="enroll-text">VIP认证已过期{this.state.overDay}天</span>
                    <span>不再享用10+项认证专项权益</span>                        
                </div>
                <Button className="auth-btn">立即续费</Button>
            </div>
        }else if(this.state.currentType == 1 && this.state.userInfo.auth_status == 0){ //未认证
            return  <div className='auth-status-box' onClick={()=>{
                    sessionStorage.setItem('sourceType','change_name_count_myenroll')
                    this.props.history.push('/auth')
                }}>
                <div className="left-box">
                    <span className="enroll-text">VIP认证</span>
                    <span>享有：改名、人数无上限等十多项权益</span>                        
                </div>
                <Button className="auth-btn">立即提交</Button>
            </div>
        }                   
    }
    // 子组件给父组件传值
    ChildrenChange(val,index){
        this.state.enrollList[index].isOpen = val
        this.setState({
            enrollList:this.state.enrollList
        })
    }

    //加载更多
    refreshData(e){
        if(this.state.loadMore && this.state.currentType){
            this.setState({
                refreshing:true,
                currentPage:this.state.currentPage+1,
            },()=>{
                this.state.currentType == 3 ? this.getSubData() : this.getManageData()
            })
        }
    }

    // 切换tabbar
    changeTab(index){
        sessionStorage.setItem('tabIndex',index)
        this.setState({
            currentPage:1,
            enrollList:[],
            currentType:index,
            refreshing:false,
            pick_status:0,
            time_status:1,
            cancel_status:-1,
            cancelText:'全部',
            pickText:'全部',
        },()=>{
            if(index == 0){ //最近浏览
                this.getEnrollData()
            }else if(index == 3){ //我关注的
                this.getSubData()
            }else{
                this.getManageData()
            }
        })
    };

    //获取我管理的数据
    getManageData(){     
        const params = {
            count:20,
            page:this.state.currentPage,
            type:this.state.currentType == 1?0:1,//0我管理的  1我参与的
            time_out:this.state.time_status
        }

        if(this.state.pick_status == 5){ //我管理的
            params.type = 10
            params.status = 0  
            params.is_create = 2
        }else if(this.state.pick_status == 6){//我发起的
            params.type = 10
            params.status = 0  
            params.is_create = 1
        }else if(this.state.pick_status > 0){
            params.status = this.state.pick_status
            params.type = 10
        } 
       
        if(this.state.currentType == 2)params.cancel_status = this.state.cancel_status

        this.props.myEnrollStore.getManageList(params).then(res=>{
            if(res.sta == 0){
                let loadMore = false
                let deactivate = '正在加载中...'
                if(res.data.length == 20){
                    loadMore = true
                }else{
                    deactivate = ''
                }
                res.data.forEach(item => {
                    item.cert_length = item.cert_list?item.cert_list.length:0
                    item.isOpen = false
                })
                res.data = WxApi.getAutoImage(res.data,150)
                let data = this.state.enrollList.concat(res.data)
                this.setState({
                    enrollList:data,
                    loadMore,
                    refreshing:false,
                    deactivate,
                    expire_bak_total:res.expire_bak_total,
                })
            }
        })
    }

    //获取最近浏览记录
    getEnrollData(){
        this.props.myEnrollStore.getEnrollHistory().then(res=>{
            if(res.sta == 0){
                this.setState({
                    enrollList:WxApi.getAutoImage(res.data,150),
                    expire_bak_total: res.bak_total,
                    deactivate:''
                })
            }
        })
    }

    // 获取我关注的列表
    getSubData(){
        const params = {
            page:this.state.currentPage,
            count:20
        }
        this.props.myEnrollStore.getSubList(params).then(res=>{
            if(res.sta ==0){
                let loadMore = false
                let deactivate = '正在加载中...'
                if(res.data.users.length == 20){
                    loadMore = true
                }else{
                    deactivate = ''
                }
                res.data.users = WxApi.changeLocalImage(res.data.users)
                let data = this.state.enrollList.concat(res.data.users)
                this.setState({
                    enrollList:data,
                    loadMore,
                    refreshing:false,
                    deactivate,
                })
            }
        })
    }

}

export default App